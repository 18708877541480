html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    padding: 0;
    padding-top: 70px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-no-bottom {
    margin: 0 !important;
}

.underline {
    text-decoration: underline;
}

tr.edit {
    font-weight: 600;
}

tr.edit.last {
    background: #f59e42;
    color: #ffffff;
}

tr.edit.deleted {
    background: #62666e;
    color: #ffffff;
}

tr.edit.locked {
    background: #dc3545;
    color: #ffffff;
}

tr.edit:hover {
    background: #17a2b8;
    color: #ffffff;
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.react-datepicker-wrapper {
    display: block !important;
}

#overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 40vh auto;
    transform: translate(-50%, -50%);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
